html,
body {
  padding: 0;
  margin: 0;
  background: #0f0f0f;
}

.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centeredSquare {
  aspect-ratio: 1/1;
  width: 95vmin;
  height: 95vmin;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.logo {
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
}

.grid-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.grid-item {
  border: 1px solid #222;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s;
  cursor: pointer;
  z-index: 1;
  font-weight: 500;
}

.grid-item:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.anlam {
  --bg-size: 400%;
  --color-one: #00f260;
  --color-two: #0575e6;
  background: linear-gradient(
      45deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)
    )
    0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: move-bg 8s infinite linear;
}

@keyframes move-bg {
  to {
    background-position: var(--bg-size) 0;
  }
}
